import * as React from "react"
import { Layout } from "../components/layout"

export default function NotFoundPage() {
  return (
    <Layout>
      <div className="no-mans-land">
        <h1 className="heading">Page Not Found</h1>
        <p className="paragraph">
          Sorry, we couldn't find what you were looking for
        </p>
      </div>
    </Layout>
  )
}
